var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "play-record-card" }, [
    _c("div", { staticClass: "play-record-card__main" }, [
      _c("div", { staticClass: "play-record-card__main_name" }, [
        _c("div", {
          staticClass: "grey",
          domProps: { textContent: _vm._s(_vm.$t("Client name")) },
        }),
        _c("div", { domProps: { textContent: _vm._s(_vm.call.client.name) } }),
      ]),
      _c("div", { staticClass: "play-record-card__main_name" }, [
        _c("div", {
          staticClass: "grey",
          domProps: { textContent: _vm._s(_vm.$t("Phone")) },
        }),
        _c("div", { domProps: { textContent: _vm._s(_vm.call.client.phone) } }),
      ]),
      _c("div", { staticClass: "play-record-card__main_name" }, [
        _c("div", {
          staticClass: "grey",
          domProps: { textContent: _vm._s(_vm.$t("Date & Time")) },
        }),
        _c("span", {
          staticClass: "mis4",
          domProps: {
            textContent: _vm._s(_vm.filters.clockTimeDate(_vm.call.start)),
          },
        }),
      ]),
      _c(
        "div",
        { staticClass: "play-record-card__main_name" },
        [
          _c("div", {
            staticClass: "grey",
            domProps: { textContent: _vm._s(_vm.$t("Call length")) },
          }),
          _c("show-time", { attrs: { seconds: _vm.lengthCall } }),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "play-record-card__btm" },
      [
        _c("recording-player", { attrs: { data: _vm.dataPlayRecorder } }),
        _c("div"),
        _c("call-type-icone", { attrs: { data: { type: _vm.call.type } } }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }